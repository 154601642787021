import { DEFAULT_LANGUAGE } from './feature-flags';
/**
 * ************************************************
 * Time & Language Property
 * ************************************************
 */
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import 'dayjs/locale/en';

/**
 * ************************************************
 * Flag to to set Bride to BOY first
 * ************************************************
 */
export const IS_BOY_FIRST = true;

/**
 * ************************************************
 * Bride & Groom Info
 * ************************************************
 */
export const GIRL_NAME = 'Levina Lammirta';
export const GIRL_NAME_SHORT = 'Levina';
export const GIRL_FATHER_NAME = `Lam Wie On`;
export const GIRL_MOTHER_NAME = `Tjong Njoen Jin`;
export const GIRL_PARENT_NAME = {
  en: `The daughter of Mr. ${GIRL_FATHER_NAME}<br /> and Mrs. ${GIRL_MOTHER_NAME}`,
  id: `Putri dari Bapak ${GIRL_FATHER_NAME}<br /> dan Ibu ${GIRL_MOTHER_NAME}`,
}
// -> boy section
export const BOY_NAME = 'Vajiro Dhammo, Syarifah';
export const BOY_NAME_SHORT = 'Vajiro';
export const BOY_FATHER_NAME = `Gunawan Widjaja`;
export const BOY_MOTHER_NAME = `Harjati Salim`;
export const BOY_PARENT_NAME = {
  en: `The son of Mr. ${BOY_FATHER_NAME}<br /> and Mrs. ${BOY_MOTHER_NAME}`,
  id: `Putra dari Bapak ${BOY_FATHER_NAME}<br /> dan Ibu ${BOY_MOTHER_NAME}`,
}
// -> bride section
export const THE_BRIDE = IS_BOY_FIRST
  ? `${BOY_NAME_SHORT} & ${GIRL_NAME_SHORT}`
  : `${GIRL_NAME_SHORT} & ${BOY_NAME_SHORT}`;

/**
 * ************************************************
 * Instagram Profile Account
 * @important please put instagram id without `@`
 * ************************************************
 */
export const IG_BOY = 'vajirodhammo';
export const IG_GIRL = 'levinalammirta';

//Instagram Filter
export const IG_FILTER = 'https://www.instagram.com/ar/3236974783274067/';
export const IMG_IG_FILTER = 'https://ik.imagekit.io/masansgaming/Elrito%20Gracia/COVER%20VAJIRO%20LEVINA_ZrzjPa6JU.jpg?updatedAt=1699595324113';

/**
 * ************************************************
 * SEO Requirement
 * @important - Don't forget to update SEO IMAGE
 * ************************************************
 */
export const SEO_IMAGE = 'https://ik.imagekit.io/basirudinansor/Vajiro%20Levina/SEO.jpg?updatedAt=1698138095729';
export const SEO_URL = 'https://invitato.net/VajiroLevina/';
export const SEO_TITLE = `The Wedding of ${THE_BRIDE} by Invitato.net`;
export const SEO_DESCRIPTION =
  DEFAULT_LANGUAGE === 'en'
    ? `Together with joyful hearts, we are pleased to announce the beginning of this new chapter of our lives together. Please click the Website Invitation link on this message for more information about our wedding details:`
    : `Menjadi sebuah kebahagiaan bagi kami untuk mengumumkan awal dari babak baru kehidupan kami bersama. Silakan klik tautan situs Undangan Website di bawah untuk informasi lebih lanjut:`;

/**
 * ************************************************
 * Time requirement for Counting Down, and
 * Remind me generator
 * @important - please convert time to Epoch time by
 * using this link https://www.epochconverter.com/
 * ************************************************
 */
export const EPOCH_START_EVENT = 1702121400;
export const EPOCH_RESEPSI_START = 1702121400;
export const EPOCH_END_EVENT = 1702132200;

/**
 * ************************************************
 * DATE REQUIREMENT
 * @important - Format date YYYY-MM-DD
 * ************************************************
 */
export const DATE_AKAD = new Date('2023-12-09');
export const DATE_RESEPSI = new Date('2023-12-09');

export const WEDDING_AKAD_TIME = '09.00 WIB';
export const WEDDING_AKAD_LOC_NAME = 'VIHARA JAKARTA DHAMMACAKKA JAYA <br /> NARADA ROOM 8TH FLOOR';
export const WEDDING_AKAD_LOC_ROAD = `Jalan Agung Permai Xv/12 Blok C, Sunter Agung Podomoro, Jakarta Utara`;
export const WEDDING_AKAD_FULLDATE = dayjs(DATE_AKAD)
  .locale(DEFAULT_LANGUAGE)
  .format('dddd, DD MMMM YYYY');

export const WEDDING_RESEPSI_TIME = '18.30 WIB';
export const WEDDING_RESEPSI_LOC_NAME = `Jw Mariott Hotel Jakarta<br /> Dua Mutiara Ballroom 2nd Floor`;
export const WEDDING_RESEPSI_LOC_ROAD = `Jalan Dr. Ide Anak Agung Gde Agung Kav. E 1.2, No. 1 & 2, Kawasan Mega Kuningan, Jakarta, Indonesia`;
export const WEDDING_RESEPSI_FULLDATE = dayjs(DATE_RESEPSI)
  .locale(DEFAULT_LANGUAGE)
  .format('dddd, DD MMMM YYYY');

  export const WEDDING_DATE = dayjs(DATE_RESEPSI)
  .locale(DEFAULT_LANGUAGE)
  .format('DD • MM • YYYY');
/**
 * ********************************************************
 * Link Generator V2
 * @important - this info will be applied at link generator
 * ********************************************************
 */
export const HOSTNAME = 'https://VajiroandLevina.com/';
export const BROADCAST_WEDDING_LOCATION = `Jw Mariott Hotel Jakarta, Dua Mutiara Ballroom 2nd Floor`;
export const BROADCAST_WEDDING_DAY = {
  id: dayjs(DATE_RESEPSI).locale('id').format('dddd, DD MMMM YYYY'),
  en: dayjs(DATE_RESEPSI).locale('en').format('dddd, DD MMMM YYYY'),
};

/**
 * ************************************************
 * Maps Location
 * ************************************************
 */
export const GOOGLE_MAPS_LINK = `https://maps.app.goo.gl/iddeaUU1TBDSMgHC7`;
export const GOOGLE_MAPS_ADDRESS = `Jw Mariott Hotel Jakarta, Dua Mutiara Ballroom 2nd Floor`;

/**
 * ************************************************
 * Backsound Copyright
 * ************************************************
 */
export const SOUND_BY = `Christina Perri - A Thousand Years`;
export const SOUND_URL = 'https://www.youtube.com/watch?v=rtOvBOTyX00';

/**
 * ************************************************
 *  Youtube Live requirement
 * ************************************************
 */
export const YOUTUBE_LINK = 'https://youtu.be/zcIIq7LR2Y4';
export const YOUTUBE_EMBED = 'https://www.youtube.com/embed/zcIIq7LR2Y4';
export const YOUTUBE_PREWEDDING_EMBED = `https://www.youtube.com/embed/WEC5RezD5jU`;
export const YOUTUBE_PREWEDDING_LINK = `https://youtu.be/WEC5RezD5jU`;

/**
 * ************************************************
 *  RSVP & Wishlish Section
 * ************************************************
 */
export const API_HOSTNAME = `https://script.google.com/macros/s/AKfycbwI50qSlnRTBg1_LsIwLtRyJAsLynWbSpocwLgmGSS0pNLJsINDXLloncGMojJrpsJ6Fg/exec`;
export const API_TABLE_NAME = 'wishlist';
export const API_RSVP_TABLE = 'rsvp';
export const SHEET_GIFT_CONFIRMATION = 'gift_confirmation';

/**
 * ************************************************
 *  Invitato Link Requirement
 * ************************************************
 */
export const URL_INVITATO = 'https://invitato.net';
export const URL_IG_INVITATO = 'https://www.instagram.com/invitato.id/';
export const URL_WA_INVITATO = 'https://wa.me/+6281329274582?text=Hi%20Kak,%20aku%20mau%20pesan%20undangan%20online%20nih!';
